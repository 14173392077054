<template>
  <span>
    <template v-if="$auth.check({ patients: 'phi' })">
      {{ patient.dob | moment('L') }}
    </template>
    <v-chip
      v-else
      label
      x-small
      dark
      color="grey darken-1"
      class="text-overline"
      v-text="$t('protected')"
    />
    <v-chip
      v-if="Number.isInteger(pediatricAge)"
      small
      color="orange xlighten-3"
      :class="{ 'mb-1': $auth.check({ patients: 'phi' }) }"
      class="ml-1 pr-2"
    >
      <v-icon
        small
        class="ml-n1 mr-1 py-2"
      >
        fas {{ pediatricAge >= 1 ? 'fa-child' : 'fa-baby' }}
      </v-icon>
      {{ pediatricAge }}
    </v-chip>
  </span>
</template>
<script>
  export default {
    props: {
      patient: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      ageDate: {
        type: [Date, String],
        default: null,
      },
    },
    computed: {
      pediatricAge () {
        if (this.ageDate) {
          let age = this.$moment(this.ageDate).diff(this.patient.dob, 'years')
          if (age < 18) {
            return age
          } else {
            return false
          }
        } else {
          return false
        }
      },
    },
  }
</script>
